<div class="docs-example-viewer-wrapper">
    <div class="docs-example-viewer-title mat-elevation-z2">
        <div class="docs-example-viewer-title-spacer">
            <h2>Book Details</h2>
        </div>
    </div>
    <div class="docs-example-viewer-body row" *ngIf="BookDetails$ | async as product" >
        <div class="col-md-3 col-sm-12">
            <mat-card-content class="image-card">
                <img class="preview-image" mat-card-image [src]="product.image.url"
                 alt="No image available">
            </mat-card-content>
        </div>
        <div class="col-md-5 col-sm-12">
            <table class="table"  >
                <tr>
                    <td><strong>Title</strong></td>
                    <td>{{product.name}}</td>
                </tr>
                <tr>
                    <td><strong>Author</strong></td>
                    <td>{{author?.name}}</td>
                </tr>
                <tr>
                    <td><strong>Category</strong></td>
                    <td>{{category?.categoryName}}</td>
                </tr>
                <tr>
                    <td><strong>Price</strong></td>
                    <td>{{product.price | currency : 'INR'}}</td>
                </tr>
            </table>
            <div fxLayoutGap="10px grid">
                <app-addtocart [bookId]="bookId" class="mr-2" ></app-addtocart>
                <app-addtowishlist  [bookId]="bookId" [showButton]="true">
                </app-addtowishlist>
            </div>
      </div>
    </div>
</div>



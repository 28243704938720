import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Book, Product } from 'src/app/models/book';
import { User } from 'src/app/models/user';
import { BookService } from 'src/app/services/book.service';
import { ImageProcessingService } from 'src/app/services/image-processing.service';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-book-card',
  templateUrl: './book-card.component.html',
  styleUrls: ['./book-card.component.scss']
})
export class BookCardComponent implements OnInit {

  @Input()
  productDetail :Product;


  
  showLoadButton = false;

  isActive = false;
  userData$: Observable<User>;

  constructor(private router: Router,private subscriptionService: SubscriptionService,private bookservice :BookService,private imagepross:ImageProcessingService) { }

  ngOnInit() {

    this.userData$ = this.subscriptionService.userData;
 
  }









  showProductDetails(id) {
    this.router.navigate(['/books/details/', {id: id}]);
  }


  goToPage(id: number) {
    this.router.navigate(['/books/details/', id]);
  }
}

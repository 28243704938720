<form>
  <input (keydown.enter)="searchStore($event)" class="searchbox" type="search" aria-label="search"
    placeholder="Search books or authors" [formControl]="searchControl" [matAutocomplete]="auto"
    (search)="cancelSearch()">
  <mat-autocomplete (optionSelected)="searchStore($event)" #auto="matAutocomplete">
    <mat-option *ngFor="let book of filteredBooks | async" [value]="book.name">
      {{book.name}}
    </mat-option>
  </mat-autocomplete>
</form>

<div class="row no-gutters pt-3">
  <div class="col-md-3 col-sm-12">
    <div class="filter-container">
      <app-book-filter [category]="category"  [categories]="categories" ></app-book-filter>
      <app-price-filter (priceValue)="filterPrice($event)"></app-price-filter>
    </div>
  </div>
  <div class="col mb-3">
    <div  class="d-flex justify-content-start card-deck-container mb-4">
      <div  class="p-1" *ngFor="let productDetail of productDetails">
        <app-book-card [productDetail]="productDetail"></app-book-card>
      </div>

    </div>
    <ng-template #nodata>
      <div class="d-flex justify-content-center mt-4">
        <h1 class="display-4" *ngIf="!isLoading">
          No books found.
        </h1>
      </div>
    </ng-template>
    <div *ngIf="isLoading" class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>

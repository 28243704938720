import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {shareReplay, map, catchError} from 'rxjs/operators';
import { Author, Book, Product } from '../models/book';
import { Categories } from '../models/categories';
import {Observable, throwError} from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BookService {
  userToken: string = '';
  baseURL = '/api/book/';

  constructor(private http: HttpClient) { }

  categories$ = this.http.get<Categories[]>(environment.URL + 'category/get').pipe(shareReplay(1));

  books$ = this.getAllBooks().pipe(shareReplay(1));

  getAllBooks() {
    return this.http.get<Product[]>(environment.URL + 'product/get');
  }
  get(): Observable <Product[]> {
    return this.http.get <Product[]> (environment.URL + "product/get")

  }
  addBook(book) {
    return this.http.post(this.baseURL, book);
  }

  getBookById(id: number) {
    return this.books$.pipe(map(book => book.find(b => b.categoryId === id)));
  }

  getsimilarBooks(bookId: number) {
    return this.http.get<Product[]>(environment.URL + "product/GetSimilarBooksByAuthor/" + bookId);
  }

  updateBookDetails(book) {
    return this.http.put(this.baseURL, book);
  }

  deleteBook(id: number) {
    return this.http.delete(this.baseURL + id);
  }

  public getProductDetailsById(id) {
    return this.http.get<Product>(environment.URL + "product/getProductById/"+id);
  }

  getProductsByCategory(categoryId: number): Observable<Product[]> {
    const url = `${this.baseURL}/category/${categoryId}`;
    return this.http.get<Product[]>(url);
  }
  getAllCategories(): Observable<Categories[]> {
    return this.http.get<Categories[]>(environment.URL + "category/get");
  }
  getauthorbyId(authorid:number): Observable<Author> {
    return this.http.get<Author>(environment.URL + "author/"+authorid);
  }
  getCategorybyId(categoryid:number): Observable<Categories> {
    return this.http.get<Categories>(environment.URL + "category/"+categoryid);
  }

  downloadProductFile(productId: number): Observable<Blob> {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer '+this.userToken
    });
    console.log(this.userToken);
    // @ts-ignore
    return this.http.get(environment.URL + `product/products/${productId}/download`, {
      headers,


      responseType: 'blob'
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Error downloading file:', error);
        return throwError(error);
      })
    );
  }



}

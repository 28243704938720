
<ng-container *ngIf="isLoginPage()">
  <app-login></app-login>
</ng-container>
<ng-container  *ngIf="isRegisterPage()">
<app-user-registration></app-user-registration>
</ng-container>
<div class="container" *ngIf="!isLoginPage()&& !isRegisterPage()">
  <app-nav-bar class="mat-elevation-z6"></app-nav-bar>
  <router-outlet></router-outlet>
</div>

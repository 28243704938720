import { Component, OnInit } from '@angular/core';
import { Book, Product, Product2 } from 'src/app/models/book';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { Observable, Subject } from 'rxjs';
import { WishlistService } from 'src/app/services/wishlist.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss']
})
export class WishlistComponent implements OnInit {
  isWishlistCleared: boolean = false;

  wishlistItems$: Observable<Product2[]>;
  isLoading: boolean;
  userId;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private subscriptionService: SubscriptionService,
    private wishlistService: WishlistService,
    private snackBarService: SnackbarService) {
    this.userId = localStorage.getItem('userId');
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.getWishlistItems();
    
  }
  
  
  


  getWishlistItems() {
    this.wishlistItems$ = this.subscriptionService.wishlistItem$;
    this.isLoading = false;
  }
  clearWishlist() {
    this.wishlistService.clearWishlist(this.userId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.subscriptionService.wishlistItemcount$.next(0);
          this.snackBarService.showSnackBar('Wishlist cleared!!!');
          this.isWishlistCleared = true; // Set the flag to true after clearing the wishlist
        },
        error => {
          console.log('Error ocurred while deleting wishlist item:', error);
          this.subscriptionService.wishlistItem$.next([]);
          console.log(this.wishlistItems$);
          this.subscriptionService.wishlistItemcount$.next(0);
          this.snackBarService.showSnackBar('Wishlist cleared!!!');
        }
      );
  }
  
}

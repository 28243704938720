import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class CategoryService {
  private selectedCategorySubject = new BehaviorSubject<string>(null);
  public selectedCategory$ = this.selectedCategorySubject.asObservable();

  public setSelectedCategory(category: string) {
    this.selectedCategorySubject.next(category);
  }
}

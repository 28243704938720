<div class="docs-example-viewer-wrapper">
    <div class="docs-example-viewer-title mat-elevation-z2">
        <div class="docs-example-viewer-title-spacer">
            <h2>Check Out</h2>
        </div>
    </div>
    <div class="docs-example-viewer-body">
        <div class="left-div">
            <mat-card class="mat-elevation-z4">
                <mat-card-title>
                    Shipping address
                </mat-card-title>
                <mat-divider></mat-divider>
                <mat-card-content>
                    <form [formGroup]="checkOutForm" (ngSubmit)="placeOrder()">
                        <mat-form-field class="full-width">
                            <input matInput placeholder="Name" formControlName="name" required>
                            <mat-error *ngIf="name.touched && name.invalid">Name is required</mat-error>
                        </mat-form-field>

                        <mat-form-field class="full-width">
                            <input matInput placeholder="Address Line 1" formControlName="addressLine1" required>
                            <mat-error *ngIf="addressLine1.touched && addressLine1.invalid">Address is required
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="full-width">
                            <input matInput placeholder="Address Line 2" formControlName="addressLine2" required>
                            <mat-error *ngIf="addressLine2.touched && addressLine2.invalid">Address is required
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="full-width">
                            <input matInput placeholder="Pincode" formControlName="pincode" required>
                            <mat-error *ngIf="pincode.touched && pincode.hasError('required')">Pincode is required
                            </mat-error>
                            <mat-error *ngIf="pincode.touched && pincode.hasError('pattern')">
                                Pincode must have 6 digits only and cannot start with 0
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="full-width">
                            <input matInput placeholder="State" formControlName="state" required>
                            <mat-error *ngIf="state.touched && state.invalid">State is required</mat-error>
                        </mat-form-field>
                        <mat-card-actions align="right">
                            <div fxFlex></div>
                            <button type="submit" mat-raised-button color="primary">Place Order</button>
                            <button type="button" mat-raised-button color="warn"
                                [routerLink]='["/shopping-cart"]'>CANCEL</button>
                        </mat-card-actions>

                    </form>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="right-div">
            <ng-template #emptycart>
                <mat-card class="mat-elevation-z4">
                    <mat-card-title>
                        Shopping cart is empty
                    </mat-card-title>
                    <mat-card-content>
                        <button mat-raised-button color="primary" [routerLink]='["/"]'>Continue shopping</button>
                    </mat-card-content>
                </mat-card>
            </ng-template>
            <mat-card *ngIf="checkOutItems && checkOutItems.orderDetails.length>0; else emptycart"
                class="mat-elevation-z4">
                <mat-card-title>
                    Order Summary
                </mat-card-title>
                <mat-card-content>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Quantity</th>
                                <th>MRP</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tr *ngFor="let item of checkOutItems.orderDetails">

                            <td><a [routerLink]="['/books/details/',item.product.id]">{{item.product.name}}</a></td>
                            <td>{{item.quantity}}</td>
                            <td>{{item.product.price| currency : 'INR'}}</td>
                            <td>{{ item.product.price * item.quantity | currency : 'INR'}}</td>
                        </tr>
                    </table>
                    <tfoot class="table">
                        <tr>
                            <th></th>
                            <th></th>
                            <td>Grand Total</td>
                            <th>{{totalPrice| currency : 'INR' }}</th>
                        </tr>
                    </tfoot>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

<mat-nav-list class="p-1 mat-elevation-z4">
  <mat-list-item [class.active-category]="!category" routerLink="/">
    <a>All Categories</a>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item [class.active-category]="category === (c.categoryName | lowercase)"
    *ngFor="let c of categories$ | async" [routerLink]="['/filter']"
    [queryParams]="{ category: c.categoryName | lowercase }">
    <a>{{ c.categoryName }}</a>
    <mat-divider></mat-divider>
  </mat-list-item>
</mat-nav-list>

<div *ngIf="!paidFor">
  <!-- Parent component template -->
  <ng-container *ngIf="dataSource.length > 0">
    <h1 class="buy-couch-heading">Buy  {{ord[0].product.name}} - {{ dataSource[0].cartTotal }} Dinar</h1>
  </ng-container>



  <!-- Image centered and responsive -->
  <div class="image-container">
    <img class="responsive-image" [src]="ord[0].product.imageModel.url" alt="Product Image">
  </div>
  <div class="btn-container">
  <button  class="btn btn-primary" (click)="onDownload(ord[0].product.id)">Download</button>
  </div>
</div>

<div *ngIf=paidFor>
  <h1>Yay, you bought a sweet couch!</h1>

  <img src="assets/logo">
</div>


<!-- Centered and conditionally hidden div -->
<div class="centered-container" [hidden]="paidFor" #paypal></div>


<div class="docs-example-viewer-wrapper">
    <div class="docs-example-viewer-title mat-elevation-z2">
        <div class="docs-example-viewer-title-spacer">
            <h2>My Orders</h2>
        </div>
    </div>
    <ng-template #noorders>
        <mat-card *ngIf="!isLoading">
            <mat-card-title>
                Looks like you have not placed any orders !!!
            </mat-card-title>
            <mat-card-content>
                <button mat-raised-button color="primary" [routerLink]='["/"]'>Start shopping</button>
            </mat-card-content>
        </mat-card>
    </ng-template>
    <div *ngIf="isLoading" class="spinner-container">
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="dataSource && dataSource.filteredData.length; else noorders" class="docs-example-viewer-body">
        <div class="full-width">
            <mat-form-field class="full-width">
                <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
            </mat-form-field>

            <mat-card-content>
                <div table-container class="mat-elevation-z2">
                    <table mat-table [dataSource]="dataSource" multiTemplateDataRows>

                        <ng-container matColumnDef="orderId">
                            <th mat-header-cell *matHeaderCellDef> Order Id </th>
                            <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                        </ng-container>

                        <ng-container matColumnDef="orderedOn">
                            <th mat-header-cell *matHeaderCellDef> Ordered On </th>
                            <td mat-cell *matCellDef="let row"> {{row.createdDate|date}} </td>
                        </ng-container>

                        <ng-container matColumnDef="orderTotal">
                            <th mat-header-cell *matHeaderCellDef> Order Total </th>
                            <td mat-cell *matCellDef="let row">{{row.cartTotal|currency : 'DINR'}}</td>
                        </ng-container>

                        <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                                <div class="example-element-detail"
                                    [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                    <mat-card class="full-width">
                                        <mat-card-content>
                                            <table class="table tbl-orderdetails mat-elevation-z2">
                                                <thead>
                                                    <tr>
                                                        <th>Book Title</th>
                                                        <th>Quantity</th>
                                                        <th>Amount Paid</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let order of element.orderDetails">
                                                        <td><a
                                                                [routerLink]="['/books/details/',order.product.id]">{{order.product.name}}</a>
                                                        </td>
                                                        <td>{{order.quantity}}</td>
                                                        <td>{{order.product.price * order.quantity|currency : 'INR'}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                        <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
                            [class.example-expanded-row]="expandedElement === element"
                            (click)="expandedElement = expandedElement === element ? null : element">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="[ 5, 10, 25, 50]"></mat-paginator>
                </div>
            </mat-card-content>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MyordersService {

  baseURL: string;

  constructor(private http: HttpClient) {
    this.baseURL = '/api/Order/';
  }

  myOrderDetails(userId: number) {
    return this.http.get(environment.URL + 'Order/getdto/' + userId);
  }
}

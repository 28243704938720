<mat-card class="book-card mat-elevation-z2" [class.on-book-card-hover]="isActive" (mouseover)="isActive=true"
    (mouseout)="isActive=false">
    <mat-card-content class="image-card" >
        <a    [routerLink]="['/books/details/',productDetail.id]" matTooltip="{{productDetail.name}}" matTooltipClass="example-tooltip"
            aria-label="Book title"><img   class="preview-image"  mat-card-image [src]="productDetail.image.url"
                alt="Book cover image"> </a>
        <div  class="favourite mat-elevation-z4" *ngIf="(userData$ | async).isLoggedIn">
            <app-addtowishlist  [bookId]="productDetail.id"></app-addtowishlist>
        </div>
     
    </mat-card-content>
    <mat-card-content >
        <div class="card-title" >
            <a (click)="showProductDetails(productDetail.id)">
                <strong>{{productDetail.name}}</strong>
            </a>
        </div>
        <p>{{  productDetail.price | currency : ' DINAR '}}   </p>
        <app-addtocart [bookId]="productDetail.id"></app-addtocart>
    </mat-card-content>
</mat-card>
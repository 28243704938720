import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Book, Product, Product2 } from '../models/book';
import { SubscriptionService } from './subscription.service';
import { ImageProcessingService } from './image-processing.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WishlistService {

  baseURL: string;

  constructor(
    private http: HttpClient,
    private subscriptionService: SubscriptionService,
    private imageprocess:ImageProcessingService) {
    this.baseURL = '/api/Wishlist/';
  }

  toggleWishlistItem(userId: number, id: number) {
    return this.http.post<Product2[]>(environment.URL + "wishlist/toggle/"+userId+"/"+id,{})
      .pipe(
        map((response: Product2[]) => {
          console.log(response + "hathajwhbhy");
          const transformedResponse = response.map((product: Product2) => {
            const image = this.imageprocess.createImages2(product);
            this.setWishlist(response);
            return image;
          });
          return transformedResponse;
        })
      );
  }
  

  getWishlistItems(userId: number) {
    return this.http.get(environment.URL + "wishlist/get/" + userId)
    .pipe(
      map((response: Product2[]) => {
        console.log(response + "hathajwhbhy");
        const transformedResponse = response.map((product: Product2) => {
          const image = this.imageprocess.createImages2(product);
          this.setWishlist(response);
          return image;
        });
        return transformedResponse;
      })
    );
  }

  setWishlist(response: Product2[]) {
    console.log(response)
    this.subscriptionService.wishlistItemcount$.next(response.length);
    this.subscriptionService.wishlistItem$.next(response);
  }

  clearWishlist(userId: number) {
    return this.http.delete<number>(environment.URL + "wishlist/"+userId, {}).pipe(
      map((response: number) => {
        console.log('hhhhh'+response);
        this.subscriptionService.wishlistItem$.next([]);
        return response;
      })
    );
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Order } from '../models/order';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  baseURL: string;

  constructor(private http: HttpClient) {
    this.baseURL = '/api/CheckOut/';
  }

  placeOrder(userId: number, checkedOutItems: Order, totalPrice: number) {
    const params = new HttpParams().set('Totalprice', totalPrice.toString());

    return this.http.post<number>(environment.URL + `Order/${userId}`, checkedOutItems, { params });
  }
}

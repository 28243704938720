import {Component, OnInit, ViewChild, ElementRef, Input} from '@angular/core';
import {ShoppingCart} from "../models/shoppingcart";
import {switchMap, takeUntil} from "rxjs/operators";
import {Order} from "../models/order";
import {MyordersService} from "../services/myorders.service";
import {forkJoin, Subject} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {Product} from "../models/book";
import {BookService} from "../services/book.service";
import {CartService} from "../services/cart.service";
import {ImageProcessingService} from "../services/image-processing.service";
declare var paypal;
@Component({
  selector: 'app-paypal',
  templateUrl: './paypal.component.html',
  styleUrls: ['./paypal.component.scss']
})
export class PaypalComponent implements OnInit {
  @ViewChild('paypal', { static: true }) paypalElement: ElementRef;
  dataSource: Order[] ;
  ord :ShoppingCart []
  products: any[] = [];
  productId: number;
  userId;
  isLoading: boolean;
  private unsubscribe$ = new Subject<void>();
  constructor(private orderService: MyordersService,private productService:BookService,private cartService:CartService,private  imageprocess:ImageProcessingService) {
    this.userId = localStorage.getItem('userId');
  }
  product = {
    price: 777.77,
    description: 'used couch, decent condition',
    img: 'assets/elibrary.jpg'
  };
  paidFor = false;
  ngOnInit() {

    paypal
      .Buttons({
        createOrder: (data, actions) => {

          return actions.order.create({
            purchase_units: [
              {
                description: this.product.description,
                amount: {
                  currency_code: 'USD',
                  value: this.dataSource[0].cartTotal,
                }
              }
            ]
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          this.paidFor = true;
          console.log(order);
        },
        onError: err => {
          console.log(err);
        }
      })

      .render(this.paypalElement.nativeElement);
    this.getMyOrderDetails();
  this. getShoppingCartItems();

  }

  onDownload(productid:number) {

      this.productService.downloadProductFile(productid).subscribe(
        (blob: Blob) => {
          const downloadLink = document.createElement('a');
          const url = window.URL.createObjectURL(blob);
          downloadLink.href = url;
          console.log("wookk")
          downloadLink.download = `product_${productid}.pdf`; // Replace the extension according to your file type
          downloadLink.click();
          window.URL.revokeObjectURL(url);
        },
        (error: any) => {
          console.error('Error downloading file:', error);
        }
      );

  }


  getMyOrderDetails() {
    this.orderService.myOrderDetails(this.userId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: Order[]) => {
        if (result != null) {
          console.log(result.find((res)=>res.orderDetails)+"hathy hya");
          this.dataSource = Object.values(result);
          this.isLoading = false;
        }
      }, error => {
        console.log('Error ocurred while fetching my order details : ', error);
      });
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }



  getShoppingCartItems() {
    this.cartService.getCartItems(this.userId)
      .pipe(
        switchMap((result: ShoppingCart[]) => {
          this.ord = result;

          this.isLoading = false;

          const mappedItems = result.map((cartItem: ShoppingCart, i) => {
            return this.imageprocess.createImages2(cartItem.product);
          });

          return forkJoin(mappedItems);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        (mappedResults: any[]) => {
          // Process the mapped results
          console.log(mappedResults);
        },
        error => {
          console.log('Error occurred while fetching shopping cart items:', error);
        }
      );
  }







}

import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { SubscriptionService } from './subscription.service';
import { UserService } from './user.service';
import { TokenStorageService } from './token-storage.service';
import { environment } from 'src/environments/environment';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  oldUserId;

  constructor(
    private http: HttpClient,
    private subscriptionService: SubscriptionService,
    private tokenStorage: TokenStorageService) { }
   

  login(user) {
    return this.http.post<any>(environment.URL + 'auth/signin', user)
      .pipe(map(response => {
        if (response && response.accessToken
          ) {
          console.log(response);
          this.oldUserId = localStorage.getItem('userId');
          localStorage.setItem('authToken', response.accessToken
          );
          this.setUserDetails();
          localStorage.setItem('userId', response.id);
          window.sessionStorage.removeItem("auth-refreshtoken");
          window.sessionStorage.setItem("auth-refreshtoken", response.refreshToken);
          this.subscriptionService.cartItemcount$.next(response.carItemCount);
        }
        console.log(response+"hatha lresponse");
        return response;

      }));
  }

  setUserDetails() {
    if (localStorage.getItem('authToken')) {
      const userDetails = new User();
      const decodeUserDetails = JSON.parse(atob(localStorage.getItem('authToken').split('.')[1]));
      console.log(decodeUserDetails);
      userDetails.id = decodeUserDetails.userid;
      userDetails.username = decodeUserDetails.sub;
      userDetails.userTypeId = Number(decodeUserDetails.userTypeId);
  

      userDetails.isLoggedIn = true;

      this.subscriptionService.userData.next(userDetails);
    }
  }

  logout() {
    localStorage.clear();
    this.resetSubscription();
    this.setTempUserId();
  }

  setTempUserId() {
    if (!localStorage.getItem('userId')) {
      const tempUserID = this.generateTempUserId();
      localStorage.setItem('userId', tempUserID.toString());
    }
  }

  generateTempUserId() {
    return Math.floor(Math.random() * (99999 - 11111 + 1) + 12345);
  }

  resetSubscription() {
    this.subscriptionService.userData.next(new User());
    this.subscriptionService.wishlistItem$.next([]);
    this.subscriptionService.wishlistItemcount$.next(0);
    this.subscriptionService.cartItemcount$.next(0);
  }


  refreshToken(token: string) {
    return this.http.post(environment.URL + 'auth/' + 'refreshtoken', {
      refreshToken: token
    }, httpOptions);
  }


}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ShoppingCart } from '../models/shoppingcart';
import { ImageProcessingService } from './image-processing.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  cartItemCount = 0;
  baseURL: string;

  constructor(private http: HttpClient,private imageprocess:ImageProcessingService) {
    this.baseURL = environment.URL + 'cart';
  }

  addBookToCart(userId: number, bookId: number) {
    return this.http.post<number>(this.baseURL +"/addToCart/"+userId+"/"+bookId, {});
  }

  getCartItems(userId: number) {
    return this.http.get(this.baseURL+"/get/"+ userId)
      .pipe(map((response: ShoppingCart[]) => {
        this.cartItemCount = response.length;
        return response;
      }));
  }

  removeCartItems(userId: number, bookId: number) {
    return this.http.delete<number>(environment.URL + "cart/decrement/"+userId+"/"+bookId, {});
  }

  deleteOneCartItem(userId: number, bookId: number) {
    return this.http.put<number>(this.baseURL +"/remove/"+userId+"/"+bookId, {});
  }

  clearCart(userId: number) {
    return this.http.delete<number>(this.baseURL + "/deleteAll/"+userId, {});
  }

  setCart(oldUserId: number, newUserId: number) {
    return this.http.get(this.baseURL + `setShoppingCart/${oldUserId}/${newUserId}`, {})
      .pipe(map((response: any) => {
        this.cartItemCount = response;
        return response;
      }));
  }
}

<mat-toolbar color="primary" class="docs-navbar-header mat-elevation-z2">
    <mat-toolbar-row>
        <div class="brand-title">
            <button mat-button [routerLink]='["/"]'>
                <mat-icon>book</mat-icon> E-Library
            </button>
            <style>
                .rounded-image {
                    border-radius: 50%;
                }
            </style>
            
            <img src="./assets/elibrary.jpg" class="rounded-image" width="70" >
        </div>
        <div>
            <app-search></app-search>
        </div>
        <div>
            <button mat-icon-button *ngIf="userData.isLoggedIn" [routerLinkActive]='["link-active"]'
                [routerLink]='["/wishlist"]'>
                <mat-icon matBadge="{{wishListCount$ | async}}" matBadgeColor="warn">favorite</mat-icon>
            </button>
            <button mat-icon-button [routerLinkActive]='["link-active"]' [routerLink]='["/shopping-cart"]'>
                <mat-icon matBadge="{{cartItemCount$ | async}}" matBadgeColor="warn">shopping_cart</mat-icon>
            </button>
            <button mat-button *ngIf="!userData.isLoggedIn" [routerLinkActive]='["link-active"]'
                [routerLink]='["/login"]'>Login</button>
            <button mat-button *ngIf="userData.userTypeId==userType.admin" [routerLink]='["/admin/books"]'>Admin
                Panel</button>

            <ng-container *ngIf="userData.isLoggedIn">
                <button mat-button [matMenuTriggerFor]="menu">
                    <mat-icon>account_circle</mat-icon> {{userData.username}} <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item [routerLink]='["/myorders"]'>My Orders</button>
                    <button mat-menu-item (click)=logout()>Logout</button>
                </mat-menu>
            </ng-container>
            <app-theme-picker></app-theme-picker>
          
              <button type="button" class="btn btn-primary me-3" [routerLink]='["/register"]'>
                Sign up for free
              </button>
        </div>
    </mat-toolbar-row>
</mat-toolbar>
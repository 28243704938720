import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  baseURL: string;

  constructor(private http: HttpClient) {
    this.baseURL = '/api/user/';
  }

  registerUser(userdetails) {
    return this.http.post(environment.URL + 'auth/signup', userdetails);
  }
  
  getCartItemCount(userId: number) {
    return this.http.get<number>(environment.URL + 'cart/count/'+ userId);
  }

  validateUserName(userName: string) {
    return this.http.get(environment.URL + 'auth/val/' + userName);
  }
}

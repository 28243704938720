import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import { Book, Product } from 'src/app/models/book';
import { ActivatedRoute, Params, Route, Router } from '@angular/router';
import { BookService } from 'src/app/services/book.service';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { ImageProcessingService } from 'src/app/services/image-processing.service';
import { HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { Categories } from 'src/app/models/categories';
import { CategoryService } from 'src/app/pipe/catservice';
import {ShoppingCart} from "../../models/shoppingcart";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {


  @Input()
  item : string;
  categories: Categories[];

  public products: Product[];
  public filteredProducts: Product[];
  public category: string;
  public priceRange: number = Number.MAX_SAFE_INTEGER;
  public isLoading: boolean;
  public searchItem: string;
  public productDetails: Product[] = [];
  categories$: Observable<Categories[]>;

  showLoadButton = false;
  constructor(
    private route: ActivatedRoute,
    private bookService: BookService,
    private subscriptionService: SubscriptionService,private imageprocess:ImageProcessingService,
    private router: Router,private categoryService:CategoryService ) {
  }

  ngOnInit() {
    this.categoryService.selectedCategory$.subscribe((category: string) => {
      // Update or fetch products based on the selected category
      this.getAllProducts();
    });


  }


  public getAllProducts() {
    console.log("clickkk");
    this.route.queryParams

    .subscribe((params: Params) => {
      this.category = params.category;
      this.searchItem = params.item;
      this.subscriptionService.searchItemValue$.next(this.searchItem);

      this.bookService.getAllBooks()
    .pipe(
      map((x: Product[], i) => x.map((product: Product) => this.imageprocess.createImages(product))),
      tap((resp: Product[]) => {
        this.filteredProducts = resp;
        this.productDetails = resp;
        if (resp.length === 12) {
          this.showLoadButton = true;
        } else {
          this.showLoadButton = false;
        }
      }),
      switchMap(() => this.bookService.getAllCategories())
    )
    .subscribe(
      (categories: Categories[]) => {
        this.categories = categories;
        this.filterBookData();
      },
      (error: HttpErrorResponse) => {
        console.log('Error fetching products:', error);
      }
      );
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          category: this.category,
          item: this.searchItem
        },
        queryParamsHandling: 'merge'
      });
    });
  };











  filterPrice(value: number) {
    this.priceRange = value;
    this.filterBookData();
  }

  filterBookData() {
    const filteredData = this.filteredProducts.filter(b => b.price <= this.priceRange).slice();

    if (this.category !== undefined) {
      const categoryId = this.categories.find(c => c.categoryName.toLowerCase() === this.category.toLowerCase())?.id;
      if (categoryId) {
        this.productDetails = filteredData.filter(b => b.categoryId === categoryId);
        console.log(this.productDetails);
      } else {
        console.log('Category not found');
      }
    } else if (this.searchItem) {
      const searchItemLowerCase = this.searchItem.toLowerCase();
      this.productDetails = filteredData.filter(
        b =>
          b.name.toLowerCase().includes(searchItemLowerCase) ||
          b.description.toLowerCase().includes(searchItemLowerCase)
      );
    } else {
      this.productDetails = filteredData;
    }
    this.isLoading = false;
  }



  fetchCategories() {
    this.categories$ = this.bookService.categories$
      .pipe(
        catchError(error => {
          console.log('Error ocurred while fetching category List : ', error);
          return EMPTY;
        }));
  }


  ngOnDestroy() {
    this.subscriptionService.searchItemValue$.next('');
  }
}

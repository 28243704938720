import { Component, OnInit } from '@angular/core';
import { Author, Book, Product } from 'src/app/models/book';
import { BookService } from 'src/app/services/book.service';
import { ActivatedRoute } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User } from 'src/app/models/user';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { ImageProcessingService } from 'src/app/services/image-processing.service';
import { Categories } from 'src/app/models/categories';


@Component({
  selector: 'app-book-details',
  templateUrl: './book-details.component.html',
  styleUrls: ['./book-details.component.scss']
})
export class BookDetailsComponent implements OnInit {
author:Author;
category:Categories;

bookId;
userData$: Observable<User>;
BookDetails$: Observable<Product>;
  constructor(
    private bookService: BookService,
    private processimg :ImageProcessingService,
    private subscriptionService: SubscriptionService,
    private route: ActivatedRoute,

) {
  this.bookId = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    


 this.route.params.subscribe(
  params => {
    this.bookId = +params.id;
    this.getBookDetails();
    this.getAuthorbyId();
    this.getCategorybyId()
  }
);

this.userData$ = this.subscriptionService.userData;
console.log(this.bookId);

  }
  getAuthorbyId() {
    // Retrieve the authorId from the BookDetails$ observable
    this.BookDetails$.subscribe((bookDetails) => {
      const authorId = bookDetails.authorId; // Assuming authorId is a property in bookDetails
  
      // Call the getauthorbyId method with the authorId
      this.bookService.getauthorbyId(authorId).subscribe(
        (author: Author) => {
          this.author = author;

        },
        (error) => {
          console.log('Error occurred while fetching author data:', error);
        }
      );
    });
  }

  getCategorybyId() {
    // Retrieve the authorId from the BookDetails$ observable
    this.BookDetails$.subscribe((bookDetails) => {
      const authorId = bookDetails.categoryId; // Assuming authorId is a property in bookDetails
  
      // Call the getauthorbyId method with the authorId
      this.bookService.getCategorybyId(authorId).subscribe(
        (author: Categories) => {
          this.category = author;
    
        },
        (error) => {
          console.log('Error occurred while fetching author data:', error);
        }
      );
    });
  }



  getBookDetails() {
    this.BookDetails$ = this.bookService.getProductDetailsById(this.bookId)
      .pipe(   map(p => this.processimg.createImages(p)),
        catchError(error => {
          console.log('Error ocurred while fetching book data : ', error);
          return EMPTY;
        }));
  }
 
}

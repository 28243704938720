import { Component } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';
import { Router, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ClientApp';
  showNavBar: boolean = true;

  constructor(private authService: AuthenticationService,private router: Router) {
  
    if (!localStorage.getItem('authToken')) {
      this.authService.setTempUserId();
    }
    this.authService.setUserDetails();
  }
  isLoginPage(): boolean {
    return this.router.url === '/login';
  }
  isRegisterPage(): boolean {
    return this.router.url === '/register';
  }
  
}
